<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Jadwal Kuliah
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="row me-0">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilterIndex" @change="index()" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiListIndex" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilterIndex" @change="index()" required
                            :disabled="prodiFilterIndex == ''">
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranListIndex" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="stambukFilterIndex" @change="index()" required :disabled="prodiFilterIndex == ''">
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in stambukListIndex" :key="list.tahun_ajaran" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                            v-model="kurikulumFilterIndex" @change="index()" :disabled="prodiFilterIndex == ''"
                            required>
                            <option value="" selected disabled>- Pilih Kurikulum</option>
                            <option v-for="list in kurikulumListIndex" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilterIndex" @change="index()" required
                            :disabled="kurikulumFilterIndex == ''">
                            <option value="" selected>- Pilih Semester</option>
                            <option v-for="list in semesterListIndex" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="kelasFilterIndex" @change="index()" required :disabled="semesterFilterIndex == ''">
                            <option value="" selected>- Pilih Kelas</option>
                            <option v-for="list in kelasListIndex" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="d-flex justify-content-between  align-items-center">
                        <div class="d-flex align-items-start justify-content-start  gap-2 pt-2">
                            <div>
                                <select class="form-select" aria-label="Default select example" name="semester"
                                    id="semester" v-model="limitIndex"
                                    @change="pageActiveIndex = 1; offsetIndex = 0; index()" required>
                                    <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                        @change=index()>
                                        {{ list.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex justify-content-center  align-items-center">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination" ref="pagi">
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActiveIndex != 1) { pageActiveIndex--; offsetIndex = offsetIndex - limitIndex; index('previous') };"
                                                aria-label="Previous" style="cursor:pointer;">
                                                <span aria-hidden="true">&laquo;</span>
                                            </span>
                                        </li>
                                        <li v-for=" list in paginglistIndex " :key="list.nama" :value="list.value"
                                            class="page-item"
                                            :class="{ 'active': list.nama == pageActiveIndex, 'd-none': list.show == false }">
                                            <span class="page-link text-black "
                                                @click="if (list.disabled != true) pageActiveIndex = list.nama; offsetIndex = list.value; index()"
                                                style="cursor: pointer;">{{
                                                    list.nama }}</span>
                                        </li>
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActiveIndex != totalPagesIndex) { pageActiveIndex++; offsetIndex = offsetIndex + limitIndex; index('next') };"
                                                aria-label="Next" style="cursor:pointer;">
                                                <span aria-hidden="true">&raquo;</span>
                                            </span>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="w-25">
                                <input type="text" v-model="queryIndex"
                                    @change="pageActiveIndex = 1; offsetIndex = 0; index()" class="form-control"
                                    placeholder="Cari nim, nama_lengkap, nama_kuliah">
                            </div>
                        </div>
                        <div class="pb-2">
                            <button v-if="create_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;" data-bs-toggle="modal" data-bs-target="#modal_tambah"
                                @click="showModal('modal_tambah')">
                                <img src="@/assets/icon/data_plus.png" alt="" width="15">
                                Tambah Jadwal Kuliah
                            </button>
                        </div>
                    </div>
                </div>
                <div class="border mt-1 rounded-3 me-2 font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                    <table class="table mb-0 bg-transparent table-borderless ">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-8">No</th>
                                <!-- <th scope="col" class="bg-transparent fs-8">Waktu Kuliah</th> -->
                                <th scope="col" class="font-custom-1 fs-8">Jam</th>
                                <th scope="col" class="font-custom-1 fs-8">Hari</th>
                                <th scope="col" class="font-custom-1 fs-8">Tipe</th>
                                <th scope="col" class="font-custom-1 fs-8">Mata kuliah</th>
                                <th scope="col" class="font-custom-1 fs-8">Dosen Utama</th>
                                <th scope="col" class="font-custom-1 fs-8">Dosen Utama 2</th>
                                <th scope="col" class="font-custom-1 fs-8">Dosen Pengajar </th>
                                <th scope="col" class="font-custom-1 fs-8">Dosen pendamping</th>
                                <th scope="col" class="font-custom-1 fs-8">Ruangan </th>
                                <th scope="col" class="font-custom-1 fs-8">Kelas </th>
                                <th scope="col" class="font-custom-1 fs-8">Prodi </th>
                                <th scope="col" class="font-custom-1 fs-8">Tahun Ajaran </th>
                                <th scope="col" class="font-custom-1 fs-8">Kurikulum </th>

                                <th scope="col" class="font-custom-1 fs-8">Tipe Semester </th>
                                <th scope="col" class="bg-transparent  fs-8">Aksi</th>
                            </tr>
                        </thead>
                        <tbody class="fs-8">
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <!-- <td>{{ record.waktu_kuliah }} </td> -->
                                <td>{{ record.jam }} ({{ record.jam_mulai }} - {{ record.jam_selesai }})</td>
                                <td>{{ record.hari }}</td>
                                <td>{{ record.tipe }}</td>
                                <td>{{ record.mata_kuliah }}</td>
                                <td>{{ record.dosen_utama }}</td>
                                <td>{{ record.dosen_utama_2 }}</td>
                                <td>{{ record.dosen_pengajar }}</td>
                                <td>{{ record.dosen_pendamping }}</td>
                                <td>{{ record.ruangan }}</td>
                                <td>{{ record.kelas }}</td>
                                <td>{{ record.prodi }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.kurikulum }}</td>

                                <td>{{ record.tipe_semester }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            v-on:click="editRecord(record)" data-bs-toggle="modal"
                                            data-bs-target="#modal_edit"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            data-bs-toggle="modal" data-bs-target="#modal_delete"
                                            v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>

                                </td>


                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>
    <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tambah">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Data Jadwal Kuliah</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.prodi" @change="prodiFilterIndex = newRecord.prodi; index()"
                                required>
                                <option value="" selected disabled>- Pilih Prodi
                                </option>
                                <option v-for="list in prodiListIndex" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" @change="tahunAjaranFilterIndex = newRecord.tahun_ajaran; index()"
                                v-model="newRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for="list in tahunAjaranListIndex" :key="list.id" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Stambuk</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="newRecord.id_stambuk"
                                @change="stambukFilterIndex = newRecord.id_stambuk; index()"
                                :disabled="newRecord.tahun_ajaran == ''" required>
                                <option value="" selected disabled>- Pilih Stambuk</option>
                                <option v-for="list in stambukListIndex" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum"
                                id="kurikulum" v-model="newRecord.kurikulum"
                                @change="kurikulumFilterIndex = newRecord.kurikulum; index()"
                                :disabled="newRecord.prodi == ''" required>
                                <option value="" selected disabled>- Pilih Kurikulum</option>
                                <option v-for="list in kurikulumListIndex" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <!-- <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                                v-model="newRecord.semester"
                                @change="index(newRecord.prodi, newRecord.tahun_ajaran, newRecord.kurikulum, newRecord.semester)"
                                :disabled="newRecord.kurikulum == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="selectedSemester" @change="semesterFilterIndex = selectedSemester; index()"
                                :disabled="newRecord.kurikulum == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterListIndex" :key="list.nama"
                                    :value="{ id: list.id, id_tahun_ajaran: list.id_tahun_ajaran }">
                                    {{ list.nama + ' (' + list.tahun_ajaran + ')' }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Kelas</label>
                            <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                                v-model="newRecord.kelas" @change="kelasFilterIndex = newRecord.kelas; index()"
                                :disabled="newRecord.prodi == '' || newRecord.id_stambuk == ''" required>
                                <option value="" selected disabled>- Pilih Kelas</option>
                                <option v-for="list in kelasListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }} ({{ list.waktu_kuliah }})
                                </option>
                            </select>
                        </div>

                        <!-- <div class="mb-3">
                            <label for="dosen" class="form-label">Waktu Kuliah </label>
                            <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.waktu_kuliah" required>
                                <option value="" selected disabled>- Pilih Waktu Kuliah</option>
                                <option v-for="list in waktuKuliahList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->



                        <div class="mb-3">
                            <label for="dosen" class="form-label">Jam Mulai</label>
                            <input type="time" class="form-control" v-model="newRecord.jam_mulai" placeholder="09:00"
                                required />
                            <!-- <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.jam" required>
                                <option value="" selected disabled>- Pilih Jam Kuliah</option>
                                <option v-for="list in jamList" :key="list.id" :value="list.id">
                                    {{ list.kode }} ({{ list.jam_mulai }}-{{ list.jam_selesai }})
                                </option>
                            </select> -->
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Jam Selesai</label>
                            <input type="time" class="form-control" v-model="newRecord.jam_selesai" placeholder="09:00"
                                required />
                            <!-- <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.jam" required>
                                <option value="" selected disabled>- Pilih Jam Kuliah</option>
                                <option v-for="list in jamList" :key="list.id" :value="list.id">
                                    {{ list.kode }} ({{ list.jam_mulai }}-{{ list.jam_selesai }})
                                </option>
                            </select> -->
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">mata Kuliah </label>
                            <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.mata_kuliah" required>
                                <option value="" selected disabled>- Pilih Mata Kuliah</option>
                                <option v-for="list in mataKuliahListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Tipe2 </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="newRecord.tipe" required :disabled="newRecord.mata_kuliah == ''">
                                <option value="" selected disabled>- Pilih Tipe</option>
                                <option value="TEORI">TEORI</option>
                                <option value="PRAKTEK" v-if="cekPraktek(newRecord.mata_kuliah)">PRAKTEK</option>
                                <option value="LAPANGAN" v-if="cekLapangan(newRecord.mata_kuliah)">LAPANGAN</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="hari" class="form-label">Hari</label>
                            <select class="form-select" aria-label="Default select example" name="hari" id="hari"
                                v-model="newRecord.hari" required>
                                <option value="" selected disabled>- Pilih Hari</option>
                                <option v-for="list in hariList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Utama</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="newRecord.dosen_utama" required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>

                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Utama 2</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="newRecord.dosen_utama_2">
                                <option value="" selected>- Pilih Dosen</option>
                                <option v-for="list in dosenListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>

                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Pendamping</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="newRecord.dosen_pendamping">
                                <option value="" selected>- Pilih Dosen</option>
                                <option v-for="list in dosenListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Pengajar</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="newRecord.dosen_pengajar" required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Ruangan</label>
                            <select class="form-select" aria-label="Default select example" name="ruangan" id="ruangan"
                                v-model="newRecord.ruangan" required>
                                <option value="" selected disabled>- Pilih Ruangan</option>
                                <option v-for="list in ruanganListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>




                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data jadwal kuliah</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_edit', 'close_modal_edit')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="currentRecord.prodi" @change="prodiFilterIndex = currentRecord.prodi; index()"
                                required>
                                <option value="" selected disabled>- Pilih Prodi
                                </option>
                                <option v-for="list in prodiListIndex" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" @change="tahunAjaranFilterIndex = currentRecord.tahun_ajaran; index()"
                                v-model="currentRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for="list in tahunAjaranListIndex" :key="list.id" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Stambuk</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.id_stambuk"
                                @change="stambukFilterIndex = currentRecord.stambuk; index()" :disabled="true" required>
                                <option value="" selected disabled>- Pilih Stambuk</option>
                                <option v-for="list in stambukListIndex" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum"
                                id="kurikulum" v-model="currentRecord.kurikulum"
                                @change="kurikulumFilterIndex = currentRecord.kurikulum; index()" required>
                                <option value="" selected disabled>- Pilih Kurikulum</option>
                                <option v-for="list in kurikulumListIndex" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="selectedSemester" @change="semesterFilterIndex = selectedSemester; index()"
                                :disabled="currentRecord.kurikulum == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterListIndex" :key="list.nama"
                                    :value="{ id: list.id, id_tahun_ajaran: list.id_tahun_ajaran }">
                                    {{ list.nama + ' (' + list.tahun_ajaran + ')' }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Kelas</label>
                            <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                                v-model="currentRecord.kelas" @change="kelasFilterIndex = currentRecord.kelas; index()"
                                :disabled="currentRecord.prodi == '' || currentRecord.id_stambuk == ''" required>
                                <option value="" selected disabled>- Pilih Kelas</option>
                                <option v-for="list in kelasListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <!-- <div class="mb-3">
                            <label for="dosen" class="form-label">Waktu Kuliah </label>
                            <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="currentRecord.waktu_kuliah" required>
                                <option value="" selected disabled>- Pilih Waktu Kuliah</option>
                                <option v-for="list in waktuKuliahList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Jam Mulai </label>
                            <!-- <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="currentRecord.jam" required>
                                <option value="" selected disabled>- Pilih Jam Kuliah</option>
                                <option v-for="list in jamList" :key="list.id" :value="list.id">
                                    {{ list.kode }} ({{ list.jam_mulai }}-{{ list.jam_selesai }})
                                </option>
                            </select> -->
                            <input type="time" class="form-control" v-model="currentRecord.jam_mulai"
                                placeholder="09:00" required />
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Jam Selesai </label>
                            <!-- <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="currentRecord.jam" required>
                                <option value="" selected disabled>- Pilih Jam Kuliah</option>
                                <option v-for="list in jamList" :key="list.id" :value="list.id">
                                    {{ list.kode }} ({{ list.jam_mulai }}-{{ list.jam_selesai }})
                                </option>
                            </select> -->
                            <input type="time" class="form-control" v-model="currentRecord.jam_selesai"
                                placeholder="09:00" required />
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">mata Kuliah </label>
                            <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="currentRecord.mata_kuliah"
                                :disabled="currentRecord.prodi == ''" required>
                                <option value="" selected disabled>- Pilih Mata Kuliah</option>
                                <option v-for="list in mataKuliahListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                            <!-- <input type="time" class="form-control" v-model="currentRecord.jam_selesai" placeholder="09:00"
                                required /> -->
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Tipe </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="currentRecord.tipe" required :disabled="currentRecord.mata_kuliah == ''">
                                <option value="" selected disabled>- Pilih Tipe</option>
                                <option value="TEORI">TEORI</option>
                                <option value="PRAKTEK" v-if="cekPraktek(currentRecord.mata_kuliah)">PRAKTEK</option>
                                <option value="LAPANGAN" v-if="cekLapangan(newRecord.mata_kuliah)">LAPANGAN</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="hari" class="form-label">Pilih Hari</label>
                            <select class="form-select" aria-label="Default select example" name="hari" id="hari"
                                v-model="currentRecord.hari" required>
                                <option value="" selected disabled>- Pilih Hari</option>
                                <option v-for="list in hariList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Utama</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.dosen_utama" required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>

                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Utama 2</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.dosen_utama_2">
                                <option value="" selected>- Pilih Dosen</option>
                                <option v-for="list in dosenListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>

                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Pendamping</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.dosen_pendamping">
                                <option value="" selected>- Pilih Dosen</option>
                                <option v-for="list in dosenListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>

                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Dosen Pengajar</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.dosen_pengajar" required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Ruangan</label>
                            <select class="form-select" aria-label="Default select example" name="ruangan" id="ruangan"
                                v-model="currentRecord.ruangan" required>
                                <option value="" selected disabled>- Pilih Ruangan</option>
                                <option v-for="list in ruanganListIndex" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>


                </div>

            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}

.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, menu_akses_cek, base_url, CloseLoading, ShowLoading, ErrorConnectionTimeOut, AlertBottom, openInNewTab, JustYearNow, NumberOnly, rupiah, rupiahInput, hariList } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {

    data() {
        return {
            //data
            url: 'jadwal_kuliah',
            api: "",

            //format
            currentYear: "",

            dosenList: [],
            mataKuliahList: [],
            ruanganList: [],
            hariList: [],
            jamList: [],
            waktuKuliahList: [],
            kelasList: [],
            prodiList: [],
            semesterList: [],
            kurikulumList: [],
            tahunAjaranList: [],

            tipeList: [
                {
                    nama: "TEORI",
                },
                {
                    nama: "PRAKTEK",
                }
            ],

            //pages
            limitList: [{
                value: 5
            }, {
                value: 10
            }, {
                value: 25
            }
            ],

            numberPageIndex: 1,
            totalPagesIndex: 0,
            currentPageActiveIndex: 1,
            pageActiveIndex: 1,
            records: [],
            currentRecordIndex: [],
            newRecordIndex: {},
            limitIndex: 5,
            offsetIndex: 0,
            batas_page_awalIndex: 0,
            batas_page_akhirIndex: 10,

            tahunAjaranListIndex: [],
            kurikulumListIndex: [],
            kelasListIndex: [],
            semesterListIndex: [],
            prodiListIndex: [],
            stambukListIndex: [],
            dosenListIndex: [],
            mataKuliahListIndex: [],
            ruanganListIndex: [],

            stambukFilterIndex: "",
            semesterFilterIndex: "",
            tahunAjaranFilterIndex: "",
            kurikulumFilterIndex: "",
            prodiFilterIndex: "",
            kelasFilterIndex: "",

            paginglistIndex: [],
            isLoadIndex: 0,
            queryIndex: "",

            //filter
            prodiFilter: "",
            tahunAjaranFilter: "",
            kurikulumFilter: "",
            semesterFilter: "",
            mataKuliahFilter: "",

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //selected
            selectedSemester: "",

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            newRecord: { dosen_utama: "", dosen_utama_2: "", dosen_pendamping: "", dosen_pengajar: "", ruangan: "", jam: "", hari: "", tipe: "", mata_kuliah: "", waktu_kuliah: "", kelas: "", prodi: "", tahun_ajaran: "", kurikulum: "", semester: "" },
            currentRecord: {},
        }
    },

    components: {
        SideNavbar,
    },


    methods: {
        //data
        rupiah,
        openInNewTab,
        NumberOnly,
        rupiahInput,

        changerupiahInputInsert(value, pilihan, status) {
            if (status == 'insert') {
                if (value.length == 5 && value.slice(-1) == ",") {
                    value = "Rp 0,-";
                    this.newRecord[pilihan] = rupiahInput(value);
                }
                this.newRecord[pilihan] = rupiahInput(value);

            } else {
                this.currentRecord[pilihan] = rupiahInput(value);

            }

        },
        // index(prodi, tahun_ajaran, kurikulum, semester) {

        //     ShowLoading();
        //     let formData = new FormData();

        //     formData.append("previous_id", '');
        //     formData.append("last_id", '');
        //     formData.append("limit", this.limit);

        //     if (prodi == undefined) {
        //         formData.append("prodi", this.prodiFilter);
        //     } else {
        //         formData.append("prodi", prodi);
        //     }

        //     if (tahun_ajaran == undefined) {
        //         formData.append("tahun_ajaran", this.tahunAjaranFilter);
        //     } else {
        //         formData.append("tahun_ajaran", tahun_ajaran);
        //     }
        //     if (kurikulum == undefined) {
        //         formData.append("kurikulum", this.kurikulumFilter);
        //     } else {
        //         formData.append("kurikulum", kurikulum);
        //     }

        //     if (semester == undefined) {
        //         formData.append("semester", this.semesterFilter);
        //     } else {
        //         formData.append("semester", semester);
        //     }


        //     formData.append("mata_kuliah", this.mataKuliahFilter);


        //     if (process.env.NODE_ENV === "development") {
        //         this.api = base_url + this.url + '_index';
        //     } else {
        //         this.api = base_url + this.url + '_index';
        //     }
        //     axios
        //         .post(this.api, formData, this.config)
        //         .then((response) => {

        //             if (response.data.status == true) {
        //                 this.dosenList = response.data.dosen;
        //                 this.ruanganList = response.data.ruangan;
        //                 this.mataKuliahList = response.data.mata_kuliah;
        //                 this.jamList = response.data.jam_kuliah;
        //                 this.waktuKuliahList = response.data.waktu_kuliah;
        //                 this.kelasList = response.data.kelas;
        //                 this.prodiList = response.data.prodi;
        //                 this.semesterList = response.data.semester;
        //                 this.kurikulumList = response.data.kurikulum;
        //                 this.tahunAjaranList = response.data.tahun_ajaran;


        //                 if (response.data.data != undefined) {
        //                     if (prodi != undefined || tahun_ajaran != undefined || kurikulum != undefined || semester != undefined) {
        //                         //asd

        //                     } else if (this.tahunAjaranFilter != "" && this.semesterFilter != "" && this.kurikulumFilter != "" && this.prodiFilter != "") {
        //                         this.records = []
        //                         this.records = response.data.data[1];

        //                     } else {
        //                         this.records = []

        //                     }
        //                     this.totalPages = response.data.data[0][0].number_page;
        //                     this.previousLastId = response.data.data[1][0].id;

        //                     let last_index = Object.keys(response.data.data[1]).length - 1;
        //                     this.NextLastId = response.data.data[1][last_index].id;
        //                 } else {
        //                     this.records = [];
        //                 }
        //                 CloseLoading();
        //             }

        //             if (response.data.status == false) {
        //                 AlertPopup("error", "", response.data.message, 1500, false);
        //             }
        //         })
        //         .catch((error) => {
        //             ErrorConnectionTimeOut(error);
        //         });

        // },
        async index(status) {

            this.paginglistIndex = [];
            ShowLoading();
            this.records = [];

            let formData = new FormData();
            formData.append("limit", this.limitIndex);
            formData.append("offset", this.offsetIndex);
            formData.append("stambuk", this.stambukFilterIndex);
            formData.append("prodi", this.prodiFilterIndex);
            formData.append("semester", this.semesterFilterIndex);
            formData.append("kurikulum", this.kurikulumFilterIndex);
            formData.append("tahun_ajaran", this.tahunAjaranFilterIndex);
            formData.append("kelas", this.kelasFilterIndex);

            formData.append("query", this.queryIndex);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }



            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.semesterListIndex = response.data.semester;
                        this.tahunAjaranListIndex = response.data.tahun_ajaran;
                        this.stambukListIndex = response.data.stambuk;
                        this.prodiListIndex = response.data.prodi;
                        this.kelasListIndex = response.data.kelas;
                        this.kurikulumListIndex = response.data.kurikulum;
                        this.dosenListIndex = response.data.dosen;
                        this.mataKuliahListIndex = response.data.mata_kuliah;
                        this.ruanganListIndex = response.data.ruangan;

                        if (response.data.data != undefined) {

                            this.records = response.data.data[1];
                            this.totalPagesIndex = response.data.data[0][0].number_page;

                            let limit = this.limitIndex;
                            this.pageActiveIndex;

                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limitIndex;
                                }

                                if (i <= this.batas_page_akhirIndex && i > this.batas_page_awalIndex) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }



                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhirIndex] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActiveIndex - 1].key) {

                                    page_list_array[this.batas_page_awalIndex].show = false;
                                    page_list_array[this.batas_page_akhirIndex].show = true;

                                    this.batas_page_awalIndex++;
                                    this.batas_page_akhirIndex++;

                                } else if (this.pageActiveIndex != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActiveIndex - 1].key) {
                                        this.batas_page_awalIndex--;
                                        this.batas_page_akhirIndex--;


                                        page_list_array[this.batas_page_awalIndex].show = true;
                                        page_list_array[this.batas_page_akhirIndex].show = false;
                                    }
                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActiveIndex - 1].key) && page_list_array[this.batas_page_akhirIndex] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActiveIndex != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglistIndex.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPagesIndex,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglistIndex.push(pemisahPages);
                                    this.paginglistIndex.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {
            if (this.totalPages <= this.numberPage) {
                return true
            }

            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);
            formData.append("prodi", this.prodiFilter);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("kurikulum", this.kurikulumFilter);
            formData.append("semester", this.semesterFilter);



            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.records = response.data.data[1];

                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage + 1;

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }

                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {
            if (this.numberPage == 1) {
                return true
            }
            let last_index = this.arrayLastIdOld.length;
            last_index = last_index - 1;
            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.arrayPreviousIdOld[last_index]);
            formData.append("last_id", "");
            formData.append("limit", this.limit);
            formData.append("prodi", this.prodiFilter);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("kurikulum", this.kurikulumFilter);
            formData.append("semester", this.semesterFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {


                        this.records = response.data.data[1];

                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage - 1;

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {

            ShowLoading();
            const formData = new FormData();
            this.newRecord.semester = this.selectedSemester.id;
            this.newRecord.tahun_ajaran = this.selectedSemester.id_tahun_ajaran;

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }



            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.records.unshift(response.data.data);

                        // Clear the form inputs
                        this.newRecord = {
                            dosen_utama: "", dosen_pendamping: "", dosen_pengajar: "", ruangan: "", jam: "", mata_kuliah: ""
                        };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        async editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.setRecordwithId('dosen_utama', this.dosenListIndex, 'nama', 'id');
            this.setRecordwithId('dosen_utama_2', this.dosenListIndex, 'nama', 'id');
            this.setRecordwithId('dosen_pendamping', this.dosenListIndex, 'nama', 'id');
            this.setRecordwithId('dosen_pengajar', this.dosenListIndex, 'nama', 'id');
            this.setRecordwithId('mata_kuliah', this.mataKuliahListIndex, 'nama', 'id');
            this.setRecordwithId('ruangan', this.ruanganListIndex, 'nama', 'id');
            // this.setRecordwithId('jam', this.jamList, 'kode', 'id');
            // this.setRecordwithId('waktu_kuliah', this.waktuKuliahList, 'nama', 'id');
            this.setRecordwithId('kelas', this.kelasListIndex, 'nama', 'id');
            this.setRecordwithId('prodi', this.prodiListIndex, 'nama', 'id');
            this.setRecordwithId('tahun_ajaran', this.tahunAjaranListIndex, 'tahun_ajaran', 'id');
            this.setRecordwithId('kurikulum', this.kurikulumListIndex, 'nama', 'id');
            this.setRecordwithId('id_stambuk', this.stambukListIndex, 'nama', 'id');
            // this.setRecordwithId('semester', this.semesterList, 'nama', 'id');
            const index = this.semesterListIndex.findIndex((record) => record.id == this.currentRecord.id_semester);
            this.currentRecord['semester'] = this.semesterListIndex[index].id;
            this.selectedSemester = { id: this.semesterListIndex[index].id, id_tahun_ajaran: this.semesterListIndex[index].id_tahun_ajaran };
            this.stambukFilterIndex = this.currentRecord.id_stambuk;


            // this.currentRecord.jumlah_dana = this.rupiahInput(this.currentRecord.jumlah_dana);




        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                if (index > -1) {
                    this.currentRecord[field] = arraylist[index][valueWantAdd];
                }
            } else {
                this.currentRecord[field] = "";
            }
        },
        cekPraktek(id_mata_kuliah) {


            const index = this.mataKuliahListIndex.findIndex((record) => record.id == id_mata_kuliah);

            const isPraktek = this.mataKuliahListIndex[index];

            if (isPraktek) {
                const sksPraktekValue = isPraktek.sks_praktek;
                if (sksPraktekValue > 0) {
                    return true;
                }
                return false;
            }
            return false;
        },
        cekLapangan(id_mata_kuliah) {
            const index = this.mataKuliahListIndex.findIndex((record) => record.id == id_mata_kuliah);
            console.log('lapangan ' + index);
            console.log(id_mata_kuliah);
            console.log(this.mataKuliahListIndex);
            const isLapangan = this.mataKuliahListIndex[index];

            if (isLapangan) {
                const sksLapanganValue = isLapangan.sks_lapangan;
                if (sksLapanganValue > 0) {
                    return true;
                }
                return false;
            }
            return false;
        },
        update() {

            ShowLoading();
            const formData = new FormData();
            this.currentRecord.semester = this.selectedSemester.id;
            this.currentRecord.tahun_ajaran = this.selectedSemester.id_tahun_ajaran;

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}
                        // this.$set(this.records, index, response.data.data);
                        this.records.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);

            formData.append("url", "ra_rekomendasi_delete");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {

                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        updateFormatted(value) {
            // Remove non-digit characters and convert to a number
            const unformattedValue = parseFloat(value.replace(/[^0-9]/g, ""));
            // Check if the value is a valid number
            if (!isNaN(unformattedValue)) {
                // Update the currentRecord object with the unformatted value
                this.newRecord.jumlah_dana = unformattedValue;
            }
        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.selectedSemester = "";
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {
            this.temporaryId = id;
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }, changeTipe() {

            this.index();
            this.numberPage = 1;
        }


    },
    computed: {

        formattedrupiah() {
            let angka = this.newRecord.jumlah_dana || 0;
            angka = "Rp " + angka.toLocaleString("id-ID");
            angka = angka + ',-'
            return angka;
        },
    },

    mounted() {
        this.newRecord.tahun_penulisan = JustYearNow();
        // const value = "12345";
        // const transformedValue = this.rupiahInput(value);

        this.hariList = hariList;
    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }

        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();

    }



}
</script>